import * as React from 'react'
import MD from "./images/md.jpg"
import CH from "./images/chr.jpg"
import ED from "./images/ED.jpg"

const MessageComponent = () => {
  return (
    <React.Fragment>
        <section className="section" id="message">
            <div className="container">
                <div className="row justify-content-between">               
                    <div className="col-md-6 col-lg-5 align-self-center">
                        <h2 className="title">Message From Chairman</h2>
                        <br />
                        <p><b>Prince Overseas (Pvt.) Limited (RL: 2071)</b> is a reputed recruiting agency in Bangladesh. I work overseas Manpower in less time without any hassle. We have been providing services through visa processing in different countries of the world including Middle east and European. 
                            We are heartily involved to raise our national income parameter by exporting skilled, semi-skilled and unskilled workers in may foreign countries through approved procedures as the bi-lateral agreement of our clients.        
                            With your every kind of manpower requirement, we are fully assuring you a complete blend of sincerity and satisfactory services and as such request you to give us an opportunity to serve your company as your most reliable manpower placement company in Bangladesh.
                        </p>
                        <p><em><b>&nbsp; -- With best regard ,  Mirza Enamul Hoque,  Chairman</b></em></p>
                    </div>
                    <div className="col-md-5 mt-4 mt-md-0">
                        <img src={CH} alt="" className="shadow w-100" />
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row justify-content-between">               
                    <div className="col-md-6 col-lg-5 align-self-center">
                        <h2 className="title"><span className="text-uppercase">MANAGING DIRECTOR’S MESSAGE</span></h2>
                        <br />
                        <p>The need for skilled manpower is always very high. With the technological revolution the need has never been higher than that of now. 
                            The need is projected to be even more acute in the days ahead. Foreign laborers are in great demand all over the world due to their work ethic and productivity, but painfully, these people are working harder and earning less than others. 
                            The gap between the labor and the income is the lack of skill, the higher the skill the lesser the gap. While our neighboring countries are exporting semi-skilled and skilled manpower instead of unskilled and they are getting greater financial solvency as well as their countries are earning higher foreign remittance, we are lagging far behind. Short technical training blended with right orientation and self-presentation can do a magic. The workers can earn high self-esteem and respect and higher remuneration at the workplace. This training and the practical knowledge will be a great wealth of their life. 
                            Even after finishing their assignment abroad, they can apply their knowledge in the country.
                        </p>
                    <p><em><b>&nbsp; -- Best regard, Mirza Md Azmarul Hoque kanon, Managing Director</b></em></p>
                    </div>
                    <div className="col-md-5 mt-4 mt-md-0">
                        <img src={MD} alt="" className="shadow w-100" />
                    </div>
                </div>
            </div>
            
            <div className="container mt-5">
                <div className="row justify-content-between">               
                    <div className="col-md-6 col-lg-5 align-self-center">
                        <h2 className="title"><span className="text-uppercase">EXECUTIVE DIRECTOR’S MESSAGE</span></h2>
                        <br />
                        <p>We have been working for overseas manpower development with a long reputation. We work honestly considering our clients very quickly at low cost. 
                            Bangladesh a nascent virgin country having with the population of 140 million people has a land area of only 1,44,000 square kilometers. 
                            The density of population is, therefore, amongst one of the highest in the world.
                            This forces us to look for outlets whereby our energetic and productive manpower can contribute to the development of the host country. 
                            Keeping in view the availability of such human resource and the need of the country, Prince Overseas (Pvt.) Limited was designed & equipped with all the requirements, to export manpower to foreign countries. 
                            We are maintain our quality of Recruitment are highly experienced team is very much away of service and acknowledged of the modern technology world.
                        </p>
                    <p><em><b>&nbsp; -- Best Regard,  Nazneen Akter Lima, Executive Director</b></em></p>
                    </div>
                    <div className="col-md-5 mt-4 mt-md-0">
                        <img src={ED} alt="" className="shadow w-100" />
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default MessageComponent