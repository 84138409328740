import * as React from 'react'

const AboutComponent = () => {
  return (
    <React.Fragment>
        <section className="section has-img-bg text-center" id="review">
            <div className="container">
                <h6 className="display-3 has-line">Prince Overseas (Pvt.) Limited</h6>
                <h5 className="mb-3 font-weight-light">A manpower consultancy firm</h5>
                <p>Prince Overseas (Pvt.) Limited was established in 2022. Today it is a fast-upcoming placement cell
                expertise in offering professional and experienced services to meet all organization's placement needs.
                Our company aims in providing exceptional leaders and o organizational solutions through a
                committed long-term client relationship built on key values of E.S.R.I.T i.e. Excellence, Service
                Responsibility, Innovation, and Teamwork. The company aims in providing professional recruitment
                services for a11 types of personnel at all levels and in a11 disciplines for a diverse range of industry
                sectors. Efficient teamwork, more importantly, add the desire to support this claim.</p>
                <p>
                We have the confidence and ability to recruit the right people at the right position, which comes from
                a vast experience and understanding of all our client's business operations. We employ only the
                best, most informed candidates countrywide and use the market sector knowledge that we have
                accumulated over the year, to the benefit of all our clients. Our candidates come through our huge
                network and our resource pools. We filter candidates for our client's requirements, matching skill
                sets, personalities, and experience to the needs that they have. This is the most added edge through
                which we can provide a complete portfolio of recruitment either for one individual or for the whole
                workforce.
                </p>
                <h6>We already have our manpower with top-level companies mostly in Gulf countries, Africa, and Far East Asia.</h6>
                <p>Today, a lot of people approach 'PRINCE OVERSEAS (PVT.) LIMITED' to meet their recruitment
needs, as a result for our good quality services and cost-effective prices and keeping lot transparencies
and last but not the least keeping professional standards. After visiting our company, everybody always
has in their mind that we have an exciting future.</p>
            </div>
        </section>
    </React.Fragment>
  )
}

export default AboutComponent