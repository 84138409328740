import * as React from "react"
import AboutComponent from "./About"
import ContactComponent from "./Contact"
import ClientComponent from "./Clients"
import MessageComponent from "./Message"
import UpcomingComponent from "./Upcoming"
import RecruitmentComponent from "./Recruitment"
import logo from "./images/logo.png"

const centerImg = {
    'display': 'block',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'paddingTop': '1px',
    'maxWidth': '40px',
    'maxHeight': '40px'
}

// markup
const HomeComponent = () => {
  return (
    <React.Fragment>
        <nav id="scrollspy" className="navbar page-navbar navbar-dark navbar-expand-md fixed-top" data-spy="affix" data-offset-top="20">
            <div className="container">
                <a className="navbar-brand" href="#"><img className="card float-left mr-2" style={centerImg} src={logo} /><strong className="text-primary text-uppercase">Prince</strong><span className="text-light text-uppercase"> Overseas</span></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#recruitment">Recruitment</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#message">Message</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#clients">Clients</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#review">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#contact">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <header className="header d-flex justify-content-center">
            <div className="container">
                <div className="row h-100 align-items-center">
                    <div className="col-md-9">
                        <div className="header-content">
                            <h4 className="header-subtitle text-uppercase">Better way to recruit </h4>
                            <p>Professional team with 13+ years of experience having candidates across all Industries.</p>
                        </div>  
                    </div>
                    
                    <div className="col-md-3">
                        <div className="container text-center" style={{"transform": "scale(0.5)"}}>
                            <div className="row pt-5">
                                <a href="" className="pricing-card popular">
                                    <div className="head">License Number</div>
                                    <div className="body">
                                        <h1><small>RL</small> 2071</h1>
                                        <p className="p-2">Prince Overseas (Pvt) Limited</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </header>

        <MessageComponent />
        <RecruitmentComponent />
        <ClientComponent />
        <UpcomingComponent />
        <AboutComponent />
        <ContactComponent />

        <footer className="footer py-4 bg-dark text-light"> 
            <div className="container text-center">
                <p className="mb-4 small">Copyright {new Date().getFullYear()} &copy; <a>Prince Overseas Private Ltd</a></p>
                <div className="social-links">
                    <a href="" className="link"><i className="ti-facebook"></i></a>
                    <a href="" className="link"><i className="ti-twitter-alt"></i></a>
                    <a href="" className="link"><i className="ti-google"></i></a>
                    <a href="" className="link"><i className="ti-pinterest-alt"></i></a>
                    <a href="" className="link"><i className="ti-instagram"></i></a>
                    <a href="" className="link"><i className="ti-rss"></i></a>
                </div>
            </div>
        </footer>

    </React.Fragment>
  )
}

export default HomeComponent
