import * as React from 'react'
import car from "./images/car.png"
import air from "./images/air.png"
import oil from "./images/oil.png"
import civil from "./images/civil.png"
import engCom from "./images/engCom.png"
import finance from "./images/finance.png"
import hospital from "./images/hospital.png"
import textile from "./images/textile.png"
import hotel from "./images/hotel.png"

const centerImg = {
  'display': 'block',
  'marginLeft': 'auto',
  'marginRight': 'auto',
  'paddingTop': '1px',
  'maxWidth': '100px',
  'maxHeight': '100px'
}

const RecruitmentComponent = () => {
  return (
    <React.Fragment>
        <section className="section" id="recruitment">
            <div className="container text-center">
                <h6 className="display-4 has-line">Where we recruit</h6>
                <p className="mb-5 pb-4">We have been recruiting Professionals, Managers, Engineers etc. to the following industries:</p>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img className='mt-2' style={centerImg}
                                          alt="Air-Conditioning and Refrigeration"
                                          src={air}/></div>
                        <h6 className="title mb-3">Air-Conditioning and Refrigeration</h6>
                    </div>
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img className='mt-1' style={centerImg}
                                          alt="Oil and Gas Industry"
                                          src={oil}/></div>
                        <h6 className="title mb-3">Oil and Gas Industry</h6>
                    </div>
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img style={centerImg}
                                          alt="Civil Construction"
                                          src={civil}/></div>
                        <h6 className="title mb-3">Civil Construction</h6>
                    </div>
                    
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img className='mt-2' style={centerImg}
                                          alt="Engineering Companies"
                                          src={engCom}/></div>
                        <h6 className="title mb-3">Engineering Companies</h6>
                    </div>
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img style={centerImg}
                                          alt="Account and Finance"
                                          src={finance}/></div>
                        <h6 className="title mb-3">Account and Finance</h6>
                    </div>
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img className='mt-2' style={centerImg}
                                          alt="Hospitals"
                                          src={hospital}/></div>
                        <h6 className="title mb-3">Hospitals</h6>
                    </div>

                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img style={centerImg}
                                          alt="Hotel / Cateries Companies"
                                          src={hotel}/></div>
                        <h6 className="title mb-3">Hotel / Cateries Companies</h6>
                    </div>
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img style={centerImg}
                                          alt="Automobile"
                                          src={car}/></div>
                        <h6 className="title mb-3">Automobile</h6>
                    </div>
                    <div className="col-lg-4">
                        <div className="circle-100 mb-3"><img className='mt-3' style={centerImg}
                                          alt="Texttile and Garments"
                                          src={textile}/></div>
                        <h6 className="title mb-3">Texttile and Garments</h6>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default RecruitmentComponent