import * as React from 'react'
import ksa from "./images/ksa.png"
import uae from "./images/uae.png"
import mlya from "./images/mlya.png"
import tower from "./images/eiffel-tower.svg"

const centerImg = {
  'display': 'block',
  'marginLeft': 'auto',
  'marginRight': 'auto',
  'paddingTop': '10px',
  'maxWidth': '80px',
  'maxHeight': '80px'
}

const ClientComponent = () => {
    return (
        <React.Fragment>
            <section className="section" id="clients">
                <div className="container">
                    <h6 className="display-3 has-line text-center">Our Clients</h6>
                    <div className="row justify-content-around">
                        <div className="col-lg-8">
                            <div className="tabs-container">
                                <ul className="nav tab-nav" id="pills-tab">
                                    <li className="item">
                                        <a className="link active" id="pills-mlya-tab" data-toggle="pill" href="#pills-mlya" aria-selected="false">
                                        <img style={centerImg}
                                            alt="Malaysia"
                                            src={mlya} />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a className="link" id="pills-ksa-tab" data-toggle="pill" href="#pills-ksa" aria-selected="true">
                                        <img style={centerImg}
                                            alt="Kingdom Of Saudi Arabia"
                                            src={ksa} />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a className="link" id="pills-uae-tab" data-toggle="pill" href="#pills-uae" aria-selected="false">
                                        <img style={centerImg}
                                            alt="United Arab Emirates"
                                            src={uae} />
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="pills-mlya">
                                        <h4 className="title">Malaysia </h4>
                                        <li>FURNIFAC  MANUFACTURING SDN.BHD</li>
                                        <li>64.GOODWOOD HECTARES SDN. BHD</li>
                                        <li>ST LIM VALUE SDN.BHD</li>
                                        <li>FU SENG FURNITURE INDUSTRIES SDN. BHD</li>
                                        <li>GREEN WORLD GENETICS SDN. BHD</li>
                                        <li>K.M. POLISHING INDUSTRIES SDN. BHD</li>
                                        <li>GINCO FURNITURE SDN.BHD</li>
                                        <li>UNITED SFL SDN. BHD</li>
                                        <li>KHAW JIM LEONG COMPANY SDN.BHD</li>
                                        <li>THE LIVING DEPOT PUCHONG SDN.BHD</li>
                                        <li>GLOBAL OUTDOORS SDN. BHD</li>
                                        <li>HENG HUP METAL SDN. BHD</li>
                                        <li>HOLZERN FURNITURE SDN. BHD</li>
                                        <li>MAXITAMA REFRIGERATIONS INDUSTRIES SDN. BHD</li>
                                        <li>UNITED  WHOLE  SDN. BHD</li>
                                        <li>FONG SONG METAL WORKS SDN. BHD</li>
                                        <li>LO SAM MANUFACTURING SDN. BHD</li>
                                        <li>KTN CONSTRUCTION SDN. BHD</li>
                                        <li>METALIFE PRODUCTS SDN. BHD</li>
                                        <li>HONG XIN CONSTRUCTION SDN. BHD</li>
                                        <li>HLGS SDN. BHD</li>
                                        <li>SEMPANA BUDI SDN. BHD</li>
                                        <li>NIKKO FIBRE SDN. BHD</li>
                                    </div>
                                    <div className="tab-pane fade" id="pills-ksa">
                                        <h4 className="title">Kingdom Of Saudi Arabia</h4>
                                        <ul className="text-info">
                                        <li>AL AYUNI CONSTRUCTION and CONTRACTING</li>
                                        <li>AL GARBIAH CEMENT FACTORY</li>
                                        <li>SGS GROUPS</li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane fade" id="pills-uae">
                                        <h4 className="title">Dubai, United Arab Emirates</h4>
                                        <ul className="text-info">
                                        <li>GOLDEN ARROW INTERIOR L.L.C</li>
                                        <li><strong>SGS GROUPS</strong></li>
                                        <li>AL GHAZAL TRANSPORT L.L.C.</li>
                                        <li>MAITHA GENERAL TRADING L.L.C</li>
                                        <li>UNITEDFOODS COMPANY</li>
                                        <li>SAM BUILDING CONTRACTING (L.L.C.)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-none d-lg-block align-self-center">
                            <img src={tower} alt="" className="w-100 tower" />
                        </div>
                    </div>                  
                </div>                      
        </section>
        </React.Fragment>
    )
}

export default ClientComponent