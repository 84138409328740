import * as React from 'react'

const ContactComponent = () => {
  return (
    <React.Fragment>
        <section className="section" id="contact">
            <div className="container text-center">
                <h6 className="display-4 has-line">CONTACT US</h6>
                <div className="font-weight-bold mb-5">
                  <p className="mb-1"><span className="text-danger">House:</span> H-102/8, BIR UTTAM ZIAUR RAHMAN SAROK,  C/A, BANANI, DHAKA-1213.</p>
                  <p className="mb-1"><span className="text-danger">Mobile:</span> +880152102144, +8801673160111, +8801619192591</p>
                  <p className="mb-1"><span className="text-danger">Email:</span> popvtltd@gmail.com</p>
                </div>
                <p className="pb-2">Prince Overseas is a manpower consultancy firm. Contact us to book your slot now.</p>
                <form>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="form-group pb-1">
                                <input type="text" className="form-control" required placeholder="Name"></input>            
                            </div>
                            <div className="form-group pb-1">
                                <input type="email" className="form-control" required placeholder="Email"></input>          
                            </div>
                            <div className="form-group ">
                                <input type="text" className="form-control" placeholder="Subject"></input>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <textarea name="" id="" cols="" rows="7" className="form-control" required placeholder="Message"></textarea>
                        </div>
                    </div>
                    <input type="submit" className="btn btn-primary btn-block" value="Send Message"></input>
                </form>
            </div>
        </section>
    </React.Fragment>
  )
}

export default ContactComponent