import * as React from 'react'

const UpcomingComponent = () => {
  return (
    <React.Fragment>
        <section className="section">
            <div className="container text-center">
                <h6 className="display-3 has-line">Upcoming clients</h6>                  
            </div>                
        </section>
    </React.Fragment>
  )
}

export default UpcomingComponent